import React from 'react'
import Layout from '../../layout/layout'
import DbSections from '../../components/db/db-sections'
import Summary from '../../components/summary'

const Job = ({ pageContext }) => (
  <Layout contextEntity={pageContext.entity} cssClass="page">
    <Summary data={pageContext.entity} />
		<div id="main-content">
			{pageContext.entity.sections ? 
				<DbSections>{pageContext.entity.sections}</DbSections>
				: ''
			}
    	</div>
  </Layout>
)

export default Job
